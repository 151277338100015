<template>
<!--	<HelloWorld msg="Welcome to Your Vue.js App"/>-->
	<RouterView />
</template>

<script>

export default {
	name: 'App',
	components: {
	}
}
</script>

<style>

body{
	display: flex;
	margin: 0;
	width: 100vw;
	height: 100vh;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	background-color: #f5f5f5;
	flex:1;
	//padding: 10px;
	display: flex;
	//margin-top: 60px;
}
</style>
