const FundApi = {
    //购买节点, 入参 token -- 节点编号
    vip_buy_node: 'api/fund/buy_node',
    //我购买的节点，无入参
    vip_my_nodes: 'api/fund/my_nodes',
    //已经售出的节点数量, 无入参
    vip_node_count: 'api/fund/nodes_count',
    //海报
    fund_banner: 'api/fund/fund_banner',
}

import HTTP from "@/request/HTTP";

export default FundApi;

export function BuyNode(data) {
    return HTTP.POST(FundApi.vip_buy_node, data, true)
}

export function MyNodes() {
    return HTTP.POST(FundApi.vip_my_nodes, {}, true)
}

export function NodeCount() {
    return HTTP.POST(FundApi.vip_node_count, {}, true)
}

export function FundBanner() {
    return HTTP.POST(FundApi.fund_banner, {}, true)
}
