import axios from "axios";

const request = axios.create({
    timeout: 86400000,
    headers: {
        "Content-Type": "multipart/form-data",
    },
    baseURL:  "/",
    // baseURL:  "https://app.sileos.fun/",

    // 这里处理 axios 将哪些 HTTP 状态码视为 resolve 或 reject
    // 如果返回 true、null、undefined，promise将会resolve；其他情况则会被 reject
    // 默认为 validateStatus: (status) => status >= 200 && status < 300
    validateStatus: () => true,
});


request.interceptors.request.use(
    async (config) => {
        // 携带 token
        // 这里请求拦截器可按自己需求修改

        const token = localStorage.getItem("token");
        config.headers["token"] = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    async (response) => {
        if (response.status === 200) {
            if (response.data.code === 1) {
                return response;
            } else {
                // Toast.show(response.data.msg, {
                //     position: Toast.positions.CENTER,
                // });
                alert(response.data.msg)
                return Promise.reject(response);
            }
        } else {
            // Toast.show(response.data.msg, {
            //     position: Toast.positions.CENTER,
            // });
            alert(response.data.msg)
            return Promise.reject(response);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default request;
