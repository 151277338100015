<script>
import BackIcon from 'vue-ionicons/dist/ios-arrow-back.vue'
import router from "@/route";
import {onMounted, ref} from "vue";
import {FundBanner} from "@/request/FundApi";

export default {
	components: {
		BackIcon
	},
	name: "DataPage",
	setup() {
		const dataUrl = ref("")
		const goToHomePage = () => {
			router.back()
		};


		onMounted(() => {
			FundBanner().then((res) => {
				console.log(res.data.data_url)
				dataUrl.value = res.data.data_url;
			});
		});

		return {
			dataUrl,
			goToHomePage
		};
	},
}
</script>

<template>
	<div style="flex: 1;display: flex;flex-direction: column">
		<div class="header">
			<BackIcon w="30px" h="30px" class="back-icon" @click="goToHomePage"/>
			<h3>数据统计</h3>
		</div>
		<iframe v-if="dataUrl !== ''" :src="dataUrl" style="flex: 1;border: 0;margin-top: 1px"></iframe>
	</div>
</template>

<style scoped>
.header {
	height: 50px;
	width: 100%;
	background: white;
	display: flex;
	flex-direction: row;
	align-items: center; /* Vertically center the children */
	justify-content: center;
	position: relative;
}

.back-icon {
	position: absolute;
	left: 10px;
}
</style>
