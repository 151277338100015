<template>
    <div class="container">
        <!-- Progress Card -->
        <div class="card-shadow center progress-card" style="display: flex;align-content: center;justify-content: center">
<!--            <h1>{{ progress }}/1000</h1>-->
            <h1>1000个节点截止</h1>
        </div>

        <!-- Nodes Section -->
        <div class="row space-between nodes-section">
            <!-- When no child nodes exist -->
            <div v-if="child.length === 0" class="center flex1"
                 :style="{ height: `${screenWidth * 0.46}px`,display:'flex'}">
                <h2>激活契约戳</h2>
            </div>

                        <!-- Render each child node -->
                        <div v-for="(item, index) in child" :key="index" class="card-shadow node-card"
                             :style="[{height:`${screenWidth * 0.46}px`,width:`${screenWidth * 0.46}px`}]">
                            <div v-if="item.uid"
                                 style="flex: 1 1 0; display: flex;justify-content: space-around;flex-direction: column;margin:10px">
<!--                                <img :src="item.avatar || defaultImage" class="node-image"/>-->
                                <div style="font-size: 20px">{{ item.username }}</div>
                                <div style="font-size: 20px">ID: {{ item.uid }}</div>
                            </div>
                            <div v-else
                                 style="flex: 1 1 0; display: flex;justify-content: space-around;flex-direction: column;align-items: center;">
                                <h3>{{ item.token }}</h3>
                                <button class="copy-button round-item" @click="copyCode(item.token)">点击复制</button>
                            </div>
                        </div>
        </div>

        <!-- Data Page Button -->
<!--        <button class="card-shadow center data-button" @click="goToDataPage">-->
<!--            <h3>查看统计数据</h3>-->
<!--        </button>-->

        <!-- Father Node Section -->
        <div v-if="father" class="card-shadow father-node" >
            <div style="display: flex;width:100%;height:100%;">

                <img :src="father.avatar || defaultImage" class="father-image"
                     :style="[{height:`${screenWidth * 0.46}px`,width:`${screenWidth * 0.46}px`}]">

                <div class="info-section"
                     style="margin:  auto;justify-content: space-between;height: 45vw;display: flex;flex-direction: column">
                    <h3>{{ father.username }}</h3>
                    <h3>ID: {{ father.uid }}</h3>
                </div>
            </div>
        </div>
        <div v-else class="card-shadow father-node"
             style="flex-direction: column"
        >
            <button class="help-button" @click="showHelp">
                <HelpIcon w="35px" h="35px"/>
            </button>
            <h2>抢购节点</h2>
            <input v-model="code" class="input" placeholder="请填写契约戳"/>
            <button class="confirm-button" @click="confirmPurchase">确认</button>
            <div style="margin-top: 30px">
              <a href="https://chat.99sq7.fun" target="_blank">原始节点专属群</a>
            </div>
        </div>
    </div>

</template>

<script>
import {onMounted, onUnmounted, ref} from 'vue';
import {useClipboard} from '@vueuse/core'; // Clipboard support
import {useRoute, useRouter} from 'vue-router';
// import { BuyNode, FundNode, MyNodes, NodeCount } from "@/request/FundApi"; // Replace with appropriate imports
import HelpIcon from 'vue-ionicons/dist/ios-help.vue'
import {BuyNode, MyNodes, NodeCount} from "@/request/FundApi";

export default {
    components: {
        HelpIcon
    },
    setup() {
        const router = useRouter();
        const route = useRoute()
        const progress = ref(0);
        const child = ref([]);
        const father = ref(null);
        const code = ref("");
        const {copy} = useClipboard();
        const defaultImage = "@/assets/default.jpg"; // Set the default image path

        const screenWidth = ref(window.innerWidth);

        const load = () => {
            NodeCount().then((res) => {
                progress.value = res.data;
            });

            MyNodes().then((res) => {
                if (!res.data) return;

                const children = [];
                if (res.data.left) children.push(res.data.left);
                if (res.data.right) children.push(res.data.right);
                child.value = children;
                if (res.data.root) father.value = res.data.root;
            });
        };

        const copyCode = (code) => {
            copy(code).then(() => {
                alert("复制成功");
            });
        };

        const goToDataPage = () => {
            router.push("/data");
        };

        const showHelp = () => {
            alert("Help");
        };

        const confirmPurchase = () => {
            if (!code.value) {
                alert("请输入契约戳");

            }

            BuyNode({token: code.value}).then(() => {
                load();
            });
        };

        onMounted(() => {
            window.addEventListener('resize', updateScreenWidth);
            console.log(screenWidth.value)
            if (route.query.token) {
                localStorage.setItem("token", route.query.token)
                load();
            }
        });

        const updateScreenWidth = () => {
            screenWidth.value = window.innerWidth;

        };

        onUnmounted(() => {
            window.removeEventListener('resize', updateScreenWidth);
        });

        return {
            screenWidth,
            progress,
            child,
            father,
            code,
            copyCode,
            goToDataPage,
            showHelp,
            confirmPurchase,
            defaultImage,
        };
    },
};
</script>

<style scoped>
.container {
    margin: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.card-shadow {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: white;
}

.flex1 {
    flex: 1;
}

.flex {
    display: flex;
}

.row {
    flex-direction: row;
    display: flex;
}

.center {
    justify-content: center;
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.space-evenly {
    justify-content: space-evenly;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.full-width {
    width: 100vw;
}

.full-height {
    height: 100vh;
}

.max-width {
    width: 100%;
}

.max-height {
    height: 100%;
}

.round-item {
    border-radius: 9999px;
    padding: 5px;
}

.text-shadow {
    text-shadow: 1px 1px 4px rgb(0, 0, 0);
}

.progress-card {
    height: 15%;
    margin-bottom: 20px;
}

.nodes-section {
    margin-bottom: 20px;
}

.node-card {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.node-image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.copy-button {
    background-color: #ffde67;
    border: none;
    padding: 10px;
    width: 100px;
}

.data-button {
    height: 10%;
    margin-bottom: 20px;
    border: unset;
    width: 100%;
    border-radius: 999px;
}

.father-node {
    display: flex;
    flex:1;
    border-radius: 20px;
    position: relative; /* 确保 help-button 是相对于父容器定位的 */
}

.father-image {
    margin: auto 10px;
    border-radius: 10px;
}

.input {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 50px;
}

.confirm-button {
    background-color: #ffde67;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin: 10px 50px;
    border-radius: 9999px;
}

.help-button {
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: #ffde67;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
    width: 40px;
    //cursor: pointer;
    border-radius: 9999px;
}
</style>
