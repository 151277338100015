import {createRouter, createWebHistory} from 'vue-router'

import HomeView from '@/components/HomePage.vue'
import DataView from '@/components/DataPage.vue'

const routes = [
    { path: '/', component: HomeView },
    { path: '/data', component: DataView },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
