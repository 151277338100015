import request from "@/request/index";

const HTTP = {
    POST: (url, data, showLoading) => {
        let formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }
        return req(url, 'POST', formData, showLoading);
    },
    GET: (url, data, showLoading) => {
        return req(url, 'GET', data, showLoading);
    },
    PUT: (url, data, showLoading) => {
        return req(url, 'PUT', data, showLoading);
    },
    DELETE: (url, data, showLoading) => {
        return req(url, 'DELETE', data, showLoading);
    },
    UPLOAD: (url, data, showLoading) => {
        return req(url, 'POST', data, showLoading);
    },
}

const req = (url, method, data) => {

    return new Promise((resolve, reject) => {
        request(url, {
            method: method,
            data
        }).then(res => {
            resolve({
                code: res.data.code,
                data: res.data.data,
                msg: res.data.msg,
                time: res.data.time
            });
        }).catch(err => {
            reject(err);
        });
    });
}

export default HTTP;
